<template>
  <v-container
          class="fill-height"
          fluid
  >
    <v-row
            align="center"
            justify="center"
    >
      <v-col
              cols="12"
              sm="12"
              md="12"
      >
        <v-card
                class="mx-auto elevation-12"
                max-width="600"
        >
          <v-img
                  class="white--text align-end"
                  height="280px"
                  :src="require('../assets/work-table.jpg')"
          >
            <v-card-title class="roboto-mono" style="text-shadow: 0 0 3px #00000078">My Projects</v-card-title>
          </v-img>


          <v-card-text class="text--primary roboto-mono">
            <!--<p><span class="font-weight-black">zakupator.org</span> <br>
            This is free platform for orders and contractors/sellers. The main idea is to help in drawing up a detailed
              orders in B2B segment and reduce the amount of unnecessary communications.</p>
            <v-card-actions>

              <v-btn
                      to="/my-projects/zakupator"
                      class="ml-auto pl-4 pr-4"
                      color="primary"
                      text
              >
                more info
              </v-btn>
            </v-card-actions>
            <v-divider></v-divider>

            <p><span class="font-weight-black">naprokat57</span> <br>
              Site about tool and some other things rent</p>
            <v-card-actions>

              <v-btn
                      to="/my-projects/naprokat57"
                      class="ml-auto pl-4 pr-4"
                      color="primary"
                      text
              >
                more info
              </v-btn>
            </v-card-actions>-->
            <template
                    v-for="(project, i) in projectsList"
            >
              <v-divider
                      v-if="i != 0"
                      :key="i"
              ></v-divider>
              <v-row
                      :key="i"
              >

                <v-col
                        sm="9"
                        cols="12"
                >
                  <span class="font-weight-black">{{ project.name }}</span> <br>
                  <span>{{ project.description }}</span>
                </v-col>
                <v-col
                        class="pt-0"
                        sm="3"
                        cols="12"
                        align-self="end">

                  <v-btn
                          :to="`/my-projects/${project.link}`"
                          class="ml-auto pl-4 pr-4 col"
                          color="primary"
                          text
                  >
                    more info
                  </v-btn>
                </v-col>
              </v-row>
            </template>




          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    export default {
        name: "MyProjects",
        data: () => ({
            projectsList: [
                {
                    name: 'zakupator.org',
                    link: 'zakupator',
                    description: 'This is free platform for orders and contractors/sellers. The main idea is to help in drawing up a detailed' +
                    ' orders in B2B segment and reduce the amount of unnecessary communications.'
                },
                {
                    name: 'yii-startup-kit',
                    link: 'yii-startup-kit',
                    description: 'This is free platform for orders and contractors/sellers. The main idea is to help in drawing up a detailed' +
                    ' orders in B2B segment and reduce the amount of unnecessary communications.'
                },
                {
                    name: 'naprokat57',
                    link: 'naprokat57',
                    description: 'Site about tool and some other things rent'
                },
                {
                    name: 'atlant5',
                    link: 'atlant5',
                    description: 'Design/web studio. Web design, graphical design. Creating web sites, brand design.'
                },
            ],
        }),
    }
</script>
