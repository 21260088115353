<template>
  <v-container
          class="fill-height"
          fluid
  >
    <v-row
            align="center"
            justify="center"
    >
      <v-col
              cols="12"
              sm="12"
              md="12"
      >
        <v-card
                class="mx-auto elevation-12"
                max-width="600"
        >

          <v-carousel
                  cycle
                  height="600"
                  hide-delimiter-background
                  show-arrows-on-hover
          >
            <v-carousel-item
                    v-for="(slide, i) in slides"
                    :key="i"
                    :src="slide.src"
            ></v-carousel-item>
          </v-carousel>
          <v-card-title class="roboto-mono">{{ projectTitle }}</v-card-title>


          <v-card-text class="text--primary roboto-mono" v-html="projectInfo">



          </v-card-text>
            <v-card-actions>
                <v-btn
                        to="/my-projects"
                        class="ml-auto"
                        color="primary"
                        text
                        right
                >
                    <v-icon left>
                        mdi-undo
                    </v-icon>
                    projects
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    export default {
        name: "ProjectPage",
        data: () => ({
            slides: [],
            projectInfo: null,
            projectTitle: null,
        }),
        mounted() {


            switch (this.$route.params.project) {
                case "zakupator":
                    this.slides = [
                        {src: '/img/projects/zakupator/1.png'},
                        {src: '/img/projects/zakupator/2.png'},
                        {src: '/img/projects/zakupator/3.png'},
                        {src: '/img/projects/zakupator/4.png'},
                        {src: '/img/projects/zakupator/5.png'},
                        {src: '/img/projects/zakupator/6.png'},
                        {src: '/img/projects/zakupator/7.png'},
                        {src: '/img/projects/zakupator/8.png'},
                        {src: '/img/projects/zakupator/9.png'},
                        {src: '/img/projects/zakupator/10.png'},
                    ];

                    this.projectInfo = 'B2B площадка заказов. Биржа поставщиков и заказчиков. <br> ' +
                        'Проект разрабатывается и поддерживается в свободное время. Имеет чистый код, почти не ломается от этого и привлекает к себе минимум внимания.' +
                        'Историю создания, принципы работы и прочую информацию можно найти на сайте <a href="https://zakupator.org" target="_blank">https://zakupator.org</a><br>' +
                        'Технологии максимально надежные и простые: Yii2, JS, Jquery, MySQL, NGINX, Ubuntu Server';
                    this.projectTitle = 'Zakupator.org';
                    break;
                case "yii-startup-kit":
                    this.slides = [
                        {
                            src: '/img/projects/kit/1.png'
                        },
                        {
                            src: '/img/projects/kit/2.png'
                        },
                    ];

                    this.projectInfo = 'Начальная заготовка для разработки онлайн проектов средних масштабов. <br> ' +
                        'Заготовка содержит в себе базовый функционал для простого сайта (микро CMS): редактирование статичных/динамичных страниц, обратная связь, авторизация, произвольные настройки, адаптированный генератор кода, админка, файловый менеджер. <br>' +
                        'Но основная задача - помочь в начале проекта - предоставить модульную структуру и начальные примеры модулей при использовании которых проект будет поддерживаемым, ' +
                        'когда разрастется до нескольких сотен таблиц. <br><br>' +
                        'Yii-startup-kit сделан на основе ряда моих проектов и масштабных проектов друзей разработчиков. Это очищенная и обновленная версия Закупатора с минимально необходимым для старта функционалом.' +
                        'Форкнуть можно тут: <a href="https://github.com/EvgenyVetrov/yii-startup-kit" target="_blank">https://github.com/EvgenyVetrov/yii-startup-kit</a> <br>' +
                        'На обновления не рассчитывайте. Вы забираете заготовку и развиваете её в свой проект без необходимости обратной совместимости. Ее задача сэкономить время на старт, и заложить правильные основы. <br> ' +
                        'Продукт пока сыроват. Вроде все готово, но нужно сделать тестовый сайт. если у кого есть готовая верстка с контентом небольшого сайта - напишите мне.';
                    this.projectTitle = 'yii-startup-kit';
                    break;

                case "naprokat57":
                    this.slides = [
                        {src: '/img/projects/naprokat57/1.png'},
                        {src: '/img/projects/naprokat57/2.png'},
                        {src: '/img/projects/naprokat57/3.png'},
                        {src: '/img/projects/naprokat57/4.png'},
                        {src: '/img/projects/naprokat57/5.png'},
                        {src: '/img/projects/naprokat57/6.png'},
                        {src: '/img/projects/naprokat57/7.png'},
                    ];

                    this.projectInfo = 'Сайт и вообще авантюра со сдачей инструмента в аренду. Был сделан с целью попробовать оффлайн проект, СЕО. Больше для развлечения, чтобы отвлечься от сложных онлайн сервисов. ' +
                        'Сайт и прочие материалы были сделаны за выходные и больше не менялись. Сайт висел в ТОП-е выдачи гуглояндекса все время. Проводилось исследование актуальных инструментов к аренде, после чего были куплены самые ходовые позиции. <br> ' +
                        'В вялотекущем режиме сдавал инструменты прямо из багажника своего авто. Занимало около 1 часа в неделю. После пары лет такого занятия забросил, и еще пару лет поступали звонки. Потом перестал платить за хостинг. <br><br>' +
                        'Сейчас продаю все наработки как есть: сайт, группу в вк, некоторый инструмент, статистику продаж и прогнозы, форму договора. <br> ' +
                        '<span class="blue--text">25 000 &#8381;</span> - микро бизнес по цене смартфона.';
                    this.projectTitle = 'naprokat57';
                    break;
                case "atlant5":

                    this.slides = [
                        {src: '/img/projects/atlant5/1.png'},
                        {src: '/img/projects/atlant5/2.png'},
                        {src: '/img/projects/atlant5/3.png'},
                        {src: '/img/projects/atlant5/4.png'},
                        {src: '/img/projects/atlant5/5.png'},
                        {src: '/img/projects/atlant5/6.png'},
                        {src: '/img/projects/atlant5/7.png'},
                        {src: '/img/projects/atlant5/8.png'},
                    ];

                    this.projectInfo = 'Дизайн студия и создание сайтов. Первый серьезный проект в плане организации работы практически всех аспектов веб студии.' +
                        'Проект стал возможен благодаря знакомству с крутым дизайнером и активному общению с разного рода предпринимателями. <br><br>' +
                        'В настоящее время проект законсервирован. Прекратил им заниматься так как стал отнимать слишком много сил и перестал приносить удовольствие. <br> ' +
                        '<span class="blue--text">2000 $</span> - набор всех материалов и площадок';
                    this.projectTitle = 'atlant5';



                    break;
                case "Mangoes":
                case "Papayas":
                    console.log("Mangoes and papayas are $2.79 a pound.");
                    break;
                default:
                    console.log("Sorry, we are out of ");
            }
        }
    }
</script>