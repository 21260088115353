<template>


    <vue-particles
            color="#f5f5f5"
            linesColor="#f5f5f5"
            class="fill-height"
            :moveSpeed="2"
            :linesWidth="2"
            :particlesNumber="50"
            fluid
    ></vue-particles>
</template>

<script>
    export default {
        name: "Relax"
    }
</script>