<template>
  <v-container
          class="fill-height"
          fluid
  >
    <v-row
            align="center"
            justify="center"
    >
      <v-col
              cols="12"
              sm="12"
              md="12"
      >
        <v-card
                class="mx-auto elevation-12"
                max-width="600"
        >
          <v-img
                  class="white--text align-end"
                  height="280px"
                  :src="require('../assets/motherboard.jpg')"
          >
            <v-card-title class="roboto-mono" style="text-shadow: 0 0 3px #00000078">This site info</v-card-title>
          </v-img>


          <v-card-text class="text--primary roboto-mono">
            This site use the next technologies stack: <br>
            - vue.js (+Vue router, +Vuetify, Vue CLI, vue-particles)<br>
            - NGINX<br>
            - Ubuntu Server<br>
            - CSS/HTML of course<br>
            <br>
            last update 28.10.2020<br>


          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    export default {
        name: "SiteInfo"
    }
</script>